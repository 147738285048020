import { ref } from 'vue'
import { useShop } from '@/use/shop'
import { GetServiceCategory, GetServiceCategoryCount } from '@/api/serviceCategory'
import { get, concat } from 'lodash'
export const useServiceCategory = () => {
  const { shopId } = useShop()
  const serviceCategoryList = ref([])
  const getAllServiceCategory = async () => {
    try {
      let max = await getServiceCategoryCount()
      max = max.data.count
      const options = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
      }
      const res = await GetServiceCategory(options)
      console.log(res)
      serviceCategoryList.value = res
      while (get(serviceCategoryList, 'value.length') < max) {
        options.start += options.limit
        const res = await GetServiceCategory(options)
        serviceCategoryList.value = concat(serviceCategoryList.value, res)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getServiceCategoryCount = async () => {
    try {
      return await GetServiceCategoryCount({ shopId: shopId.value })
    } catch (error) {
      console.log(error)
    }
  }
  return { getAllServiceCategory, serviceCategoryList }
}
