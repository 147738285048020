<template>
  <div>
    <AddButton content="新增子項目" style="margin-bottom: 22px" @click="dialog.create = true" />
    <BaseTable :data="sortTableData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="圖片" prop="image" width="200">
        <template slot-scope="scope">
          <img style="margin: auto" width="90" :src="imgSource(scope.row.Image)" alt="">
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="子項目名稱" prop="name" />
      <BaseElTableColumn prop="bookingTime" label="實際預約時間" width="150" align="center" />
      <BaseElTableColumn prop="showTime" label="顯示預約時間" width="150" align="center">
        <template slot-scope="scope">
          {{ !scope.row.showTime || scope.row.showTime === 0 ? '-' : scope.row.showTime }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="price" label="實際價格" width="120" align="center" />
      <BaseElTableColumn v-if="(chargeType === 'checkout' && useFeatures.forceCanHidePrice) || chargeType !== 'checkout'" prop="showPrice" label="顯示價格" width="120" align="center">
        <template slot-scope="scope">
          {{ !scope.row.showPrice || scope.row.showPrice === '0' ? '-' : scope.row.showPrice }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="排序" prop="order" />
      <BaseElTableColumn prop="name" label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit=";(dialog.edit = true), (selectRow = scope.row)"
            @delete=";(dialog.delete = true), (selectRow = scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <DeleteDialog
      v-if="dialog.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="dialog.delete = false"
      @delete="deleteSubService"
    />

    <CreateChildServiceDialog
      v-if="dialog.create"
      type="create"
      :mainService="mainService"
      :configData="configData"
      @created="onSubServiceCreate"
      @close="dialog.create = false"
    />
    <CreateChildServiceDialog
      v-if="dialog.edit"
      type="edit"
      :service="selectRow"
      :mainService="mainService"
      :configData="configData"
      @edited="onSubServiceEdit"
      @close="dialog.edit = false"
    />
  </div>
</template>

<script>
import tableMixin from '@/mixin/table'
import EmptyBlock from '@/components/EmptyBlock.vue'
import CreateChildServiceDialog from './CreateChildServiceDialog.vue'
import { GetSubService } from '@/api/service'
import { findIndex, map, get } from 'lodash'
import { imgSrc } from '@/utils/helper'
import { computed } from 'vue'
import { usePermissions } from '@/use/permissions'

export default {
  name: 'ChildServiceTable',
  components: { EmptyBlock, CreateChildServiceDialog },
  mixins: [tableMixin],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    mainService: {
      type: Object,
      default: () => ({}),
    },
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props) {
    const { checkAction } = usePermissions()
    const chargeType = computed(() => {
      return get(props.configData, 'reservation.chargeType')
    })
    const useFeatures = computed(() => {
      return {
        forceCanHidePrice: checkAction('admin.appointmentSubService.enableShowPrice'),
      }
    })

    return {
      chargeType,
      useFeatures,
    }
  },
  data: () => ({
    tableData: [],
    imgSize: 300,
  }),
  computed: {
    compactData () {
      const dataList = []
      this.tableData.forEach((item) => {
        if (!item.hidePrice) item.showPrice = '0'
        if (!item.hideTime) item.showTime = '0'
        const data = {
          name: item.name,
          image: get(item, 'Image.id'),
          description: item.description || undefined,
          price: item.price,
          showPrice: item.showPrice === '' ? '0' : item.showPrice,
          showTime: item.showTime === '' ? 0 : item.showTime,
          bookingTime: item.bookingTime,
          order: item.order,
          allAppointmentUnit: !item.serviceUnits.length,
          allResourceItem: item.resourceItems ? !item.resourceItems.length : false,
          appointmentUnits: map(item.serviceUnits, 'id'),
          resourceItems: map(item.resourceItems, 'id'),
        }
        if (item.id) data.id = item.id
        dataList.push(data)
      })
      return dataList
    },
    sortTableData () {
      let services = this.tableData
      if (!services) return []
      services = services.sort((a, b) => a.order - b.order)
      return services
    },
  },
  watch: {
    compactData () {
      this.$emit('update', this.compactData)
    },
  },
  mounted () {
    this.data.forEach((i) => this.syncData(i))
  },
  methods: {
    async refresh () {
      this.loading = true
      await this.getService()
      this.loading = false
    },

    imgSource (image) {
      return imgSrc(this.imgSize, image)
    },

    async getService () {
      console.log('here')
      const [res, err] = await GetSubService({
        shopId: this.shop,
        start: this.pageStartIndex,
        limit: this.tableOptions.pageLimit,
        name: this.nameSearch || undefined,
      })
      console.log('here', res)
      if (err) {
        this.$message.error(err)
        return
      }
      if (res) this.tableData = res
    },

    async deleteSubService () {
      this.tableData = this.tableData.filter((i) => i.id !== this.selectRow.id)
      // await this.refresh()
      this.$message.success('已刪除服務!')
      this.dialog.delete = false
    },

    onSubServiceCreate (data) {
      this.tableData.push(data)
    },
    onSubServiceEdit (data) {
      const targetIndex = findIndex(this.tableData, this.selectRow)
      if (targetIndex !== -1) {
        this.$set(this.tableData, targetIndex, data)
        // this.tableData[targetIndex] = data
      }
    },
    syncData (res) {
      const data = {
        avatar: res.avatar,
        name: res.name,
        description: res.description,
        isPublic: res.isPublic,
        order: res.order,
        price: res.price,
        Image: res.Image,
        bookingTime: res.bookingTime,
        serviceUnits: res.AppointmentUnits,
        resourceItems: res.ResourceItems,
        id: res.id,
      }
      if (res.showPrice !== '0') {
        data.hidePrice = true
        data.showPrice = res.showPrice
      }
      if (res.showTime !== '0') {
        data.hideTime = true
        data.showTime = res.showTime
      }
      this.tableData.push(data)
    },
  },
}
</script>
